import { Modal } from "antd";
import React, { useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import { useDispatch, useSelector } from "react-redux";
import { STATUSES } from "../../config/constants/defaultValues";
import { getJobDetailsByIdRequest } from "../../redux/jobsSlice";
import Loading from "../shared/GeneralComponents";

const JobViewModal = ({
  isModalOpen,
  setIsModalOpen,
  modalData = null,
  setModelData = undefined,
}) => {
  const handleCancel = () => {
    setModelData(null);
    setIsModalOpen(false);
  };
  console.log("modalData", modalData);
  const dispatch = useDispatch();
  //   const navigate = useNavigate();
  //   const { jobID } = useParams();

  const { status, jobDetails } = useSelector((state) => state.jobsList);

  useEffect(() => {
    if (modalData?.jobID) {
      dispatch(getJobDetailsByIdRequest({ jobID: modalData?.jobID }));
    }
  }, [dispatch, modalData]);
  return (
    <>
      <Modal
        className="modal-styles"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        // width={600}
        maskClosable={false}
        centered={true}
        title={false}
      >
        <div className="row">
          <div className="col-md-12">
            <h5 className="table-heading mb-0 ">View Job Details</h5>
          </div>
        </div>
        <div className="modal-content p-4">
          {status === STATUSES.LOADING ? (
            <Loading height={"20vh"} />
          ) : status === STATUSES.ERROR ? (
            <div tip="Error" />
          ) : (
            <div className="section-style">
              <div className="row space-between w-100">
                <div className="col-md-12 col-xs-12">
                  <div className="d-flex align-items-center justify-content-start pl-3">
                    <div>
                      <h6 className="list-heading">
                        {jobDetails?.job?.discipline.name ?? " "}
                      </h6>

                      <p className="mb-0 sub-heading">
                        {jobDetails?.job?.location_detail?.name ?? ""}
                      </p>
                      <p className="mb-0 sub2-heading">
                        {jobDetails?.job?.salary}{" "}
                        <span>{jobDetails?.job?.salary_period}</span>
                      </p>
                    </div>
                  </div>
                  <hr style={{ marginTop: "20px" }} />
                </div>
              </div>

              <div className="row space-between">
                <div className="col-md-12 px-2">
                  <h6 className="heading-title">Job Requirements</h6>

                  <h6 className="test-p-list text-break">
                    {ReactHtmlParser(
                      jobDetails?.job?.requirements ??
                        jobDetails?.job?.discipline?.requirements ??
                        ""
                    )}
                  </h6>
                </div>
              </div>
              <div className="row space-between">
                <div className="col-md-12 px-2">
                  <h6 className="heading-title">Job Benefits</h6>
                  <h6 className="test-p-list text-break">
                    {ReactHtmlParser(
                      jobDetails?.job?.benefits ??
                        jobDetails?.job?.discipline?.benefits ??
                        ""
                    )}
                  </h6>
                </div>
              </div>
              <div className="row space-between">
                <div className="col-md-12 px-2">
                  <h6 className="heading-title">Job Description</h6>
                  <h6 className="test-p-list text-break">
                    {ReactHtmlParser(
                      jobDetails?.job?.description ??
                        jobDetails?.job?.discipline?.description ??
                        ""
                    )}
                  </h6>
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default JobViewModal;
